<template>
  <div>
    <v-layout class="mainfont" wrap justify-center pb-7>
      <v-flex xs11 pt-4>
        <v-layout wrap justify-center>
          <v-snackbar v-model="showSnackBar" color="black">
            <v-layout wrap justify-center>
              <v-flex text-left class="align-self-center">
                <span style="color: white">
                  {{ msg }}
                </span>
              </v-flex>
              <v-flex text-right>
                <v-btn small :ripple="false" text @click="showSnackBar = false">
                  <v-icon style="color: white">mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-snackbar>
          <vue-element-loading
            :active="appLoading"
            :is-full-screen="true"
            background-color="#FFFFFF"
            color="#42275a"
            spinner="spinner"
          />
          <v-flex xs12>
            <v-card class="pb-4">
              <v-layout wrap justify-start>
                <v-flex xs12 pl-3 pt-3 pb-4 class="bgcustom">
                  <span style="font-size: 25px; color: white"
                    >Application Number - {{ list.applicationNo }}</span
                  >
                </v-flex>
                <v-flex xs12>
                  <v-layout wrap pb-3>
                    <v-flex xs12>
                      <v-divider></v-divider>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs5 lg3 text-left pl-0 pl-lg-3>
                  <span class="subheading" style="font-size: 16px">
                    Applicant Name
                  </span>
                  <br />
                  <span>{{ list.applicantName }}</span>
                </v-flex>
                <v-flex xs5 lg3 text-left>
                  <span class="subheading" style="font-size: 16px">
                    District
                  </span>
                  <br />
                  <span>{{ list.applicantDistrict }}</span>
                </v-flex>

                <v-flex xs5 lg3 text-left>
                  <span class="subheading" style="font-size: 16px">
                    Email
                  </span>
                  <br />
                  <span>{{ list.applicantEmail }}</span>
                </v-flex>
                <v-flex xs5 lg3 text-left>
                  <span class="subheading" style="font-size: 16px">
                    Mobile Number
                  </span>
                  <br />
                  <span>{{ list.applicantMobileNo }}</span>
                </v-flex>
                <v-flex xs5 lg3 text-left pl-0 pl-lg-3 pt-4>
                  <span class="subheading" style="font-size: 16px">
                    Address
                  </span>
                  <br />
                  <span>{{ list.applicantAddress }}</span>
                </v-flex>
                <v-flex xs5 lg3 text-left pt-4>
                  <span class="subheading" style="font-size: 16px">
                    Pincode
                  </span>
                  <br />
                  <span>{{ list.applicantPinCode }}</span>
                </v-flex>
                <v-flex xs5 lg3 text-left pt-4>
                  <span class="subheading" style="font-size: 16px">
                    Id Type
                  </span>
                  <br />
                  <span>{{ list.idType }}</span>
                </v-flex>
                <v-flex xs5 lg3 text-left pt-4>
                  <span class="subheading" style="font-size: 16px">
                    Id Card No
                  </span>
                  <br />
                  <span>{{ list.idcardNo }}</span>
                </v-flex>
                <v-flex xs5 lg3 text-left pt-4 pl-0 pl-lg-3>
                  <span class="subheading" style="font-size: 16px">
                    Nature Of Application
                  </span>
                  <br />
                  <span>{{ list.natureOfApplication }}</span>
                </v-flex>
                <v-flex xs5 lg3 text-left pt-4>
                  <span class="subheading" style="font-size: 16px">
                    Id Proof </span
                  >&nbsp;

                  <v-btn
                    class="bgcustom"
                    dark
                    download
                    :href="baseURL + 'file/get/' + idProof"
                    target="_blank"
                    ><span
                      style="
                        text-transform: none;
                        font-family: mainfont;
                        font-size: 12px;
                      "
                      >View
                    </span></v-btn
                  >
                  <br />
                </v-flex>
                <v-flex xs5 lg3 text-left pt-4>
                  <span class="subheading" style="font-size: 16px">
                    Progress </span
                  >&nbsp;

                  <v-btn
                    class="bgcustom"
                    dark
                    @click="$router.push('/records?id=' + id)"
                    ><span
                      style="
                        text-transform: none;
                        font-family: mainfont;
                        font-size: 12px;
                      "
                      >Progress
                    </span></v-btn
                  >
                  <!-- <v-btn class="bgcustom" dark @click="navigateToRecords(id)">
                    <span style="text-transform: none; font-family: mainfont; font-size: 12px;">Progress</span>
                  </v-btn> -->

                  <br />
                </v-flex>
                <v-flex
                  xs5
                  lg3
                  text-left
                  pt-4
                  v-if="list.animalinvolved === 'YES'"
                >
                  <span class="subheading" style="font-size: 16px">
                    Preshoot Order </span
                  >&nbsp;

                  <v-btn
                    class="bgcustom"
                    dark
                    download
                    :href="baseURL + 'file/get/' + Preshootfile"
                    target="_blank"
                    ><span
                      style="
                        text-transform: none;
                        font-family: mainfont;
                        font-size: 12px;
                      "
                      >View
                    </span></v-btn
                  >
                  <br />
                </v-flex>
                <v-flex
                  xs5
                  lg3
                  text-left
                  pl-0
                  pl-lg-3
                  pt-4
                  v-if="list.animalinvolved === 'YES'"
                >
                  <span class="subheading" style="font-size: 16px">
                    Species Name
                  </span>
                  <br />
                  <span>{{ list.species }}</span>
                </v-flex>
                <v-flex
                  xs5
                  lg3
                  text-left
                  pt-4
                  v-if="list.animalinvolved === 'YES'"
                >
                  <span class="subheading" style="font-size: 16px">
                    No Of Animals
                  </span>
                  <br />
                  <span>{{ list.noOfAnimal }}</span>
                </v-flex>
                <v-flex
                  xs5
                  lg3
                  text-left
                  pt-4
                  v-if="list.animalinvolved === 'YES'"
                >
                  <span class="subheading" style="font-size: 16px">
                    Preshoot Number
                  </span>
                  <br />
                  <span>{{ list.preshootNumber }}</span>
                </v-flex>
                <v-flex
                  xs5
                  lg3
                  text-left
                  pt-4
                  v-if="list.animalinvolved === 'YES'"
                >
                  <span class="subheading" style="font-size: 16px">
                    Preshoot Valid From
                  </span>
                  <br />
                  <span>{{ formatDate(list.validityfromDate) }}</span>
                </v-flex>
                <v-flex
                  xs5
                  lg3
                  text-left
                  pl-0
                  pl-lg-3
                  pt-4
                  v-if="list.animalinvolved === 'YES'"
                >
                  <span class="subheading" style="font-size: 16px">
                    Preshoot Valid To
                  </span>
                  <br />
                  <span>{{ formatDate(list.validitytoDate) }}</span>
                </v-flex>
                <v-flex xs5 lg3 text-left pt-4>
                  <span class="subheading" style="font-size: 16px">
                    Security Deposit
                  </span>
                  <br />
                  <span>{{ list.securitydeposit }}Rs</span>
                </v-flex>
                <v-flex xs5 lg3 text-left pt-4 pl-3>
                  <span class="subheading" style="font-size: 16px">
                    Shooting Fees
                  </span>
                  <br />
                  <span>{{ list.shootingfee }}Rs</span>
                </v-flex>
                <v-flex
                  xs5
                  lg3
                  text-left
                  pt-4
                  v-if="list.rangeMahassars && list.rangeReports"
                >
                  <span class="subheading" style="font-size: 16px">
                    Refund Amount
                  </span>
                  <br />
                  <span>{{ list.refundAmount }}Rs</span>
                </v-flex>
                <v-flex xs12 pa-5>
                  <v-card
                    elevation="0"
                    style="border: 1px solid black; border-radius: 0px"
                  >
                    <v-layout wrap justify-center>
                      <v-flex xs12>
                        <v-layout wrap class="subheading">
                          <v-flex xs2 text-center pt-2> Location </v-flex>
                          <v-flex xs2 text-center pt-2> Film Name </v-flex>
                          <v-flex xs1 text-center pt-2> From Date </v-flex>
                          <v-flex xs2 text-center pt-2> To Date </v-flex>
                          <v-flex xs2 text-center pt-2> No Of Members </v-flex>
                          <v-flex xs2 text-center pt-2> No Of Vehicles </v-flex>
                          <v-flex xs1 text-center pt-2> No Of Days </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12> </v-flex>
                    </v-layout>
                    <v-layout wrap pt-4 pb-4>
                      <v-flex xs12>
                        <v-divider></v-divider>
                      </v-flex>
                    </v-layout>
                    <v-layout
                      pb-3
                      wrap
                      v-for="(item, i) in filmShootingDetails"
                      :key="i"
                    >
                      <v-flex xs2 text-center pt-2>
                        {{ item.location }}
                      </v-flex>
                      <v-flex xs2 text-center pt-2>
                        {{ item.filmName }}
                      </v-flex>
                      <v-flex xs1 text-center pt-2>
                        {{ formatDate(item.fromDate) }}
                      </v-flex>
                      <v-flex xs2 text-center pt-2>
                        {{ formatDate(item.toDate) }}
                      </v-flex>
                      <v-flex xs2 text-center pt-2>
                        {{ item.noOfMembers }}
                      </v-flex>
                      <v-flex xs2 text-center pt-2>
                        {{ item.noOfVehicles }}
                      </v-flex>
                      <v-flex xs1 text-center pt-2>
                        {{ item.shootingDuration }}
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center>
          <v-flex xs12 pt-16>
            <v-layout wrap justify-center>
              <v-flex xs3 v-if="list.paymentStatus !== 'Success'">
                <v-text-field
                  label="Security Deposit"
                  type="number"
                  dense
                  color="#283e51"
                  outlined
                  background-color="white"
                  v-model="list.securitydeposit"
                  hide-details
                  class="inputPrice"
                >
                  <template v-slot:label>
                    <span
                      class="custom-label-text"
                      style="color: black; font-size: 14px"
                    >
                      Security Deposit
                      <span style="color: rgb(255, 0, 0)">*</span>
                    </span>
                  </template>
                </v-text-field> </v-flex
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <v-flex xs3 v-if="list.paymentStatus !== 'Success'">
                <v-text-field
                  label="Film Shooting Fees"
                  type="number"
                  dense
                  color="#283e51"
                  outlined
                  background-color="white"
                  v-model="list.shootingfee"
                  hide-details
                  class="inputPrice"
                >
                  <template v-slot:label>
                    <span
                      class="custom-label-text"
                      style="color: black; font-size: 14px"
                    >
                      Film Shooting Fees
                      <span style="color: rgb(255, 0, 0)">*</span>
                    </span>
                  </template>
                </v-text-field>
              </v-flex>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <v-flex
                xs3
                v-if="list.rangeMahassars && list.rangeMahassars.length > 0"
                pr-16
              >
                <v-text-field
                  label="Refund Amount"
                  type="number"
                  dense
                  color="#283e51"
                  outlined
                  background-color="white"
                  v-model="list.refundAmount"
                  hide-details
                  class="inputPrice"
                >
                  <template v-slot:label>
                    <span
                      class="custom-label-text"
                      style="color: black; font-size: 14px"
                    >
                      Refund Amount
                      <span style="color: rgb(255, 0, 0)">*</span>
                    </span>
                  </template>
                </v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center>
              <v-flex
                xs3
                pt-6
                v-if="
                  list.paymentStatus !== 'Success' ||
                  (list.rangeMahassars && list.rangeMahassars.length > 0)
                "
              >
                <v-btn @click="submitfee()" color="green" block type="submit">
                  <span style="color: white">UPDATE</span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap pt-4>
          <v-flex xs12 v-if="user === 'range'">
            <v-card>
              <v-layout wrap justify-center>
                <v-flex xs12>
                  <v-layout wrap justify-center pa-3>
                    <v-flex xs12 lg11 text-center pa-2>
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>DOWNLOAD APPLICATION</v-flex>
                        <v-flex xs2>
                          <v-btn
                            block
                            class="text-capitalize bgcustom"
                            @click="getApplication()"
                          >
                            <v-icon color="white">mdi-download</v-icon>

                            <span style="color: white; font-size: small">
                              APPLICATION</span
                            >
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs12 lg11 text-center pa-2>
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>DOWNLOAD ACKNOWLEDGMENT</v-flex>
                        <v-flex xs2>
                          <v-btn
                            block
                            class="text-capitalize bgcustom"
                            @click="getAcknowledgment()"
                          >
                            <v-icon color="white">mdi-download</v-icon>
                            <span style="color: white; font-size: small">
                              ACKNOWLEDGMENT</span
                            >
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex
                      xs12
                      lg11
                      text-center
                      v-if="list.userAgreement"
                      pa-2
                    >
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>VIEW AGREEMENT</v-flex>
                        <v-flex xs2>
                          <v-btn
                            block
                            class="bgcustom"
                            dark
                            download
                            :href="baseURL + 'file/get/' + list.userAgreement"
                            target="_blank"
                          >
                            <v-icon color="white">mdi-eye</v-icon>&nbsp;

                            <span
                              style="
                                text-transform: none;
                                font-family: mainfont;
                                font-size: 12px;
                              "
                            >
                              AGREEMENT
                            </span></v-btn
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs12 lg11 text-center pa-2>
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left> UPLOAD MAHASAR</v-flex>
                        <v-flex xs2>
                          <v-btn
                            block
                            class="bgcustom"
                            @click="dialogVisible = true"
                          >
                            <v-icon color="white">mdi-upload</v-icon>

                            <span style="font-size: small; color: white">
                              MAHASAR
                            </span>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 lg11 text-center pa-2>
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left> UPLOAD REPORT</v-flex>
                        <v-flex xs2>
                          <v-btn
                            block
                            class="bgcustom"
                            @click="dialogVisible2 = true"
                          >
                            <v-icon color="white">mdi-upload</v-icon>

                            <span style="font-size: small; color: white">
                              REPORT
                            </span>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex
                      xs12
                      lg11
                      text-center
                      v-if="list.rangeMahassar && list.rangeReport"
                      pa-2
                    >
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left> FORWARD TO CLERK</v-flex>
                        <v-flex xs2 text-center>
                          <v-btn
                            block
                            class="text-capitalize"
                            @click="forwardToClerk2()"
                            color="orange"
                          >
                            <v-icon color="white">mdi-share</v-icon>&nbsp;
                            <span style="color: white">FORWARD</span>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12>
            <v-layout wrap justify-center>
              <v-flex
                xs4
                pt-2
                v-if="
                  list.paymentInitiation === 'YES' &&
                  list.paymentStatus === 'Pending'
                "
              >
                <v-icon color="green">mdi-clock-check-outline</v-icon>
                <span style="color: green; font-weight: bold">
                  Waiting for payment approval
                </span>
              </v-flex>
              <v-flex
                xs3
                pb-4
                pl-3
                pr-2
                text-center
                v-if="
                  list.paymentInitiation == 'YES' &&
                  list.paymentStatus ==
                    'Success' /* && !list.rangeMahassars && !list.rangeReports */ &&
                  list.rangeMahassars.length === 0 &&
                  list.rangeReports.length === 0
                "
              >
                <v-autocomplete
                  clearable
                  dense
                  outlined
                  label="Range"
                  color="black"
                  :items="range"
                  v-model="selectedRanges"
                  item-text="officeName"
                  item-value="_id"
                  item-color="#FF1313"
                  hide-details
                  multiple
                  class="custom-autocomplete mainfont"
                >
                  <template v-slot:label>
                    <span
                      class="custom-label-text"
                      style="color: black; font-size: 14px"
                    >
                      Send to Range
                    </span>
                  </template>
                  <template v-slot:append>
                    <v-icon color="black">mdi-chevron-down</v-icon>
                  </template>
                </v-autocomplete>
                <br />

                <v-btn
                  block
                  type="submit"
                  class="text-capitalize"
                  @click="forwardToRange()"
                  color="orange"
                >
                  <v-icon color="white">mdi-share</v-icon>&nbsp;
                  <span style="color: white">FORWARD</span>
                </v-btn>
              </v-flex>
              <v-flex
                xs4
                pb-4
                text-center
                v-else-if="
                  list.paymentInitiation === 'NO' &&
                  list.paymentStatus === 'Pending'
                "
              >
                <v-btn
                  block
                  type="submit"
                  class="text-capitalize"
                  @click="forwardToUsers(), (forwarddialog = true)"
                  color="orange"
                >
                  <v-icon color="white">mdi-share</v-icon>&nbsp;
                  <span style="color: white">FORWARD</span>
                </v-btn>
              </v-flex>
              <v-flex
                xs3
                pb-4
                pl-3
                pr-2
                text-center
                v-else-if="
                  list.rangeMahassars.length > 0 && list.rangeReports.length > 0
                "
              >
                <v-autocomplete
                  clearable
                  dense
                  outlined
                  label="Range"
                  color="black"
                  :items="range"
                  v-model="selectedRanges"
                  item-text="officeName"
                  item-value="_id"
                  item-color="#FF1313"
                  hide-details
                  multiple
                  class="custom-autocomplete mainfont"
                >
                  <template v-slot:label>
                    <span
                      class="custom-label-text"
                      style="color: black; font-size: 14px"
                    >
                      Send to Range
                    </span>
                  </template>
                  <template v-slot:append>
                    <v-icon color="black">mdi-chevron-down</v-icon>
                  </template>
                </v-autocomplete>
                <br />
              </v-flex>
              <v-flex
                xs2
                pb-4
                text-center
                v-if="list.paymentInitiation !== 'YES'"
              >
                <v-btn
                  plain
                  class="text-capitalize"
                  @click="settoforward(), (procceddialog = true)"
                  color="green"
                >
                  <v-icon size="140%" color="green">mdi-check</v-icon>&nbsp;
                  <span>PROCEED</span>
                </v-btn>
              </v-flex>
              <!-- <v-flex xs2 pb-4 text-center pl-5 v-if="list.rangeMahassars && list.rangeReports">
                <v-btn class="text-capitalize" @click="refund(item._id)" color="green">
                  <v-icon size="140%" color="white">mdi-check</v-icon>&nbsp;
                  <span style="color:white">REFUND</span>
                </v-btn>
              </v-flex> -->
              <v-flex
                xs2
                pb-4
                text-center
                pl-5
                v-if="
                  list.rangeMahassars &&
                  list.rangeReports &&
                  list.rangeMahassars.length > 0 &&
                  list.rangeReports.length > 0
                "
              >
                <v-btn
                  block
                  type="submit"
                  class="text-capitalize"
                  @click="forwardToRange()"
                  color="orange"
                >
                  <v-icon color="white">mdi-share</v-icon>&nbsp;
                  <span style="color: white">FORWARD</span>
                </v-btn>
              </v-flex>
              <v-flex
                xs2
                pb-4
                text-center
                pl-5
                v-if="
                  list.rangeMahassars &&
                  list.rangeReports &&
                  list.rangeMahassars.length > 0 &&
                  list.rangeReports.length > 0
                "
              >
                <v-btn
                  class="text-capitalize"
                  @click="refunddialog = true"
                  color="green"
                >
                  <v-icon size="140%" color="white">mdi-check</v-icon>&nbsp;
                  <span style="color: white">Approve Refund</span>
                </v-btn>
              </v-flex>
              <v-flex xs1 pb-4 v-if="list.paymentStatus !== 'Success'">
                <v-btn
                  block
                  class="text-capitalize"
                  color="red"
                  @click="rejectdialog = true"
                >
                  <span style="color: white; font-size: small">REJECT</span>
                  <v-icon color="white">mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 v-if="user === 'user'">
            <v-card>
              <v-layout wrap justify-center>
                <v-flex xs12>
                  <v-layout wrap justify-center pa-3>
                    <v-flex
                      xs12
                      lg11
                      text-center
                      v-if="list.cancelRequest === 'No'"
                    >
                      <!-- <v-layout wrap justify-center>
                          <v-flex xs3 text-left>CANCEL APPLICATION</v-flex>
                          <v-flex xs2>
                            <v-btn block class="text-capitalize" @click="(deletedialog = true)" color="red">
                              <v-icon color="white">mdi-cancel</v-icon>
                              <span style="color:white;font-size: small;">CANCEL</span>
                            </v-btn>
                          </v-flex>
                        </v-layout> -->
                    </v-flex>
                    <v-flex
                      xs12
                      lg11
                      text-center
                      v-if="list.cancelRequest === 'Yes'"
                    >
                      <v-layout wrap justify-center>
                        <v-flex xs5 text-left style="color: red"
                          >REQUESTED FOR CANCELLATION</v-flex
                        >
                        <!-- <v-flex xs2>
                            <v-btn block class="text-capitalize" @click="(deletedialog = true)" color="red">
                              <v-icon color="white">mdi-cancel</v-icon>
                              <span style="color:white;font-size: small;">CANCEL</span>
                            </v-btn>
                          </v-flex> -->
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 lg11 text-center pa-2>
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>DOWNLOAD APPLICATION</v-flex>
                        <v-flex xs2>
                          <v-btn
                            block
                            class="text-capitalize bgcustom"
                            @click="getApplication()"
                          >
                            <v-icon color="white">mdi-download</v-icon>

                            <span style="color: white; font-size: small">
                              APPLICATION</span
                            >
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 lg11 text-center pa-2>
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>DOWNLOAD ACKNOWLEDGMENT</v-flex>
                        <v-flex xs2>
                          <v-btn
                            block
                            class="text-capitalize bgcustom"
                            @click="getAcknowledgment()"
                          >
                            <v-icon color="white">mdi-download</v-icon>
                            <span style="color: white; font-size: small">
                              ACKNOWLEDGMENT</span
                            >
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex
                      xs12
                      lg11
                      text-center
                      v-if="list.userAgreement"
                      pa-2
                    >
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>VIEW AGREEMENT</v-flex>
                        <v-flex xs2>
                          <v-btn
                            block
                            class="bgcustom"
                            dark
                            download
                            :href="baseURL + 'file/get/' + list.userAgreement"
                            target="_blank"
                          >
                            <v-icon color="white">mdi-eye</v-icon>&nbsp;

                            <span
                              style="
                                text-transform: none;
                                font-family: mainfont;
                                font-size: 12px;
                              "
                            >
                              AGREEMENT
                            </span></v-btn
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex
                      xs12
                      lg11
                      text-center
                      v-if="list.paymentInitiation === 'YES'"
                      pa-2
                    >
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>UPLOAD AGREEMENT</v-flex>
                        <v-flex xs2>
                          <v-btn
                            block
                            color="#2e8b57"
                            @click="openDialog = true"
                          >
                            <v-icon color="white">mdi-upload</v-icon>

                            <span style="font-size: small; color: white">
                              AGREEMENT
                            </span>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex
                      xs12
                      lg11
                      text-center
                      v-if="list.paymentInitiation === 'YES'"
                      pa-2
                    >
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>DOWNLOAD AGREEMENT</v-flex>
                        <v-flex xs2>
                          <v-btn
                            block
                            class="text-capitalize"
                            @click="getAgreement()"
                            color="blue"
                          >
                            <v-icon color="white">mdi-download</v-icon>
                            <span style="color: white; font-size: small">
                              AGREEMENT
                            </span>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex
                      xs12
                      lg11
                      text-center
                      v-if="
                        list.paymentInitiation === 'YES' &&
                        list.paymentStatus === 'Pending'
                      "
                      pa-2
                    >
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left> FEE PAYMENT</v-flex>
                        <v-flex xs2>
                          <v-btn block color="#2e8b57" @click="feePayment()">
                            <span style="font-size: small; color: white">
                              Fee Payment
                            </span>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex
                      xs12
                      lg11
                      text-center
                      v-if="list.paymentStatus === 'Success'"
                      pa-2
                    >
                      <v-layout wrap justify-center>
                        <v-flex xs12 lg12 text-center>
                          <span style="color: #228b22; font-weight: bold"
                            >Payment Done</span
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>

          <v-flex
            xs12
            v-else-if="
              user === 'section' || user === 'superclerk' || user === 'division'
            "
          >
            <v-card>
              <v-layout wrap justify-center>
                <v-flex xs12>
                  <v-layout wrap justify-center pa-3>
                    <v-flex
                      xs12
                      lg11
                      text-center
                      v-if="list.status !== 'Rejected'"
                    >
                      <!-- <v-layout wrap justify-center>
                          <v-flex xs3 text-left>CANCEL APPLICATION</v-flex>
                          <v-flex xs2>
                            <v-btn block class="text-capitalize" @click="(deletedialog = true)" color="red">
                              <v-icon color="white">mdi-cancel</v-icon>
                              <span style="color:white;font-size: small;">CANCEL</span>
                            </v-btn>
                          </v-flex>
  
                        </v-layout> -->
                    </v-flex>
                    <v-flex xs12 lg11 text-center pa-2>
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>DOWNLOAD APPLICATION</v-flex>
                        <v-flex xs2>
                          <v-btn
                            block
                            class="text-capitalize bgcustom"
                            @click="getApplication()"
                          >
                            <v-icon color="white">mdi-download</v-icon>

                            <span style="color: white; font-size: small">
                              APPLICATION</span
                            >
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs12 lg11 text-center pa-2>
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>DOWNLOAD ACKNOWLEDGMENT</v-flex>
                        <v-flex xs2>
                          <v-btn
                            block
                            class="text-capitalize bgcustom"
                            @click="getAcknowledgment()"
                          >
                            <v-icon color="white">mdi-download</v-icon>
                            <span style="color: white; font-size: small">
                              ACKNOWLEDGMENT</span
                            >
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex
                      xs12
                      lg11
                      text-center
                      v-if="list.userAgreement"
                      pa-2
                    >
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>VIEW AGREEMENT</v-flex>
                        <v-flex xs2>
                          <v-btn
                            block
                            class="bgcustom"
                            dark
                            download
                            :href="baseURL + 'file/get/' + list.userAgreement"
                            target="_blank"
                          >
                            <v-icon color="white">mdi-eye</v-icon>&nbsp;

                            <span
                              style="
                                text-transform: none;
                                font-family: mainfont;
                                font-size: 12px;
                              "
                            >
                              AGREEMENT
                            </span></v-btn
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex
                      xs12
                      lg11
                      text-center
                      pa-2
                      v-if="list.task === 'T6'"
                    >
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>DOWNLOAD PROCEEDINGS</v-flex>
                        <v-flex xs2>
                          <v-btn
                            block
                            class="text-capitalize bgcustom"
                            @click="getProceedings()"
                          >
                            <v-icon color="white">mdi-download</v-icon>
                            <span style="color: white; font-size: small">
                              PROCEEDINGS</span
                            >
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex
                      xs12
                      lg11
                      text-center
                      pa-2
                      v-if="list.task === 'T6'"
                    >
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>DOWNLOAD LICENSE</v-flex>
                        <v-flex xs2>
                          <v-btn
                            block
                            class="text-capitalize bgcustom"
                            @click="getLicense()"
                          >
                            <v-icon color="white">mdi-download</v-icon>
                            <span style="color: white; font-size: small">
                              LICENSE</span
                            >
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex
                      xs12
                      lg11
                      text-center
                      pa-2
                      v-if="list.task === 'T6'"
                    >
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>DOWNLOAD LETTER TO RANGE</v-flex>
                        <v-flex xs2>
                          <v-btn
                            block
                            class="text-capitalize bgcustom"
                            @click="getLetter()"
                          >
                            <v-icon color="white">mdi-download</v-icon>
                            <span style="color: white; font-size: small">
                              LETTER</span
                            >
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex
                      xs12
                      lg11
                      text-center
                      pa-2
                      v-if="list.task === 'T6'"
                    >
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left> UPLOAD PROCEEDINGS</v-flex>
                        <v-flex xs2>
                          <v-btn
                            block
                            class="bgcustom"
                            @click="dialogVisibleproceedings = true"
                          >
                            <v-icon color="white">mdi-upload</v-icon>
                            <span style="font-size: small; color: white">
                              PROCEEDINGS
                            </span>
                          </v-btn> </v-flex
                        >&nbsp;&nbsp;&nbsp;
                        <v-flex xs2 v-if="list.proceedings">
                          <v-btn
                            block
                            color="blue"
                            dark
                            download
                            :href="baseURL + 'file/get/' + list.proceedings"
                            target="_blank"
                          >
                            <v-icon color="white">mdi-eye</v-icon>&nbsp;
                            <span
                              style="
                                text-transform: none;
                                font-family: mainfont;
                                font-size: 12px;
                              "
                            >
                              VIEW PROCEEDINGS
                            </span></v-btn
                          >
                        </v-flex>
                        <!-- <v-flex xs2 v-if="list.tempproceedings">
                          <v-btn class="mainfont" @click="uploadProceedings()" color="error" :disabled="uploading"><v-icon left>
                              mdi-upload
                            </v-icon>UPLOAD</v-btn>
                        </v-flex> -->
                      </v-layout>
                    </v-flex>
                    <v-flex
                      xs12
                      lg11
                      text-center
                      pa-2
                      v-if="list.task === 'T6'"
                    >
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left> UPLOAD LICENSE</v-flex>
                        <v-flex xs2>
                          <v-btn
                            block
                            class="bgcustom"
                            @click="dialogVisiblelicense = true"
                          >
                            <v-icon color="white">mdi-upload</v-icon>
                            <span style="font-size: small; color: white">
                              LICENSE
                            </span>
                          </v-btn> </v-flex
                        >&nbsp;&nbsp;&nbsp;
                        <v-flex xs2 v-if="list.license">
                          <v-btn
                            block
                            color="blue"
                            dark
                            download
                            :href="baseURL + 'file/get/' + list.license"
                            target="_blank"
                          >
                            <v-icon color="white">mdi-eye</v-icon>&nbsp;
                            <span
                              style="
                                text-transform: none;
                                font-family: mainfont;
                                font-size: 12px;
                              "
                            >
                              VIEW LICENSE
                            </span></v-btn
                          >
                        </v-flex>
                        <!-- <v-flex xs2 v-if="list.templicense">
                          <v-btn class="mainfont" @click="uploadLicense()" color="error" :disabled="uploading"><v-icon left>
                              mdi-upload
                            </v-icon>UPLOAD</v-btn>
                        </v-flex> -->
                      </v-layout>
                    </v-flex>
                    <v-flex
                      xs12
                      lg11
                      text-center
                      pa-2
                      v-if="list.task === 'T6'"
                    >
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left> UPLOAD LETTER TO RANGE</v-flex>
                        <v-flex xs2>
                          <v-btn
                            block
                            class="bgcustom"
                            @click="dialogVisibleletter = true"
                          >
                            <v-icon color="white">mdi-upload</v-icon>
                            <span style="font-size: small; color: white">
                              LETTER
                            </span>
                          </v-btn> </v-flex
                        >&nbsp;&nbsp;&nbsp;
                        <v-flex xs2 v-if="list.letterToRange">
                          <v-btn
                            block
                            color="blue"
                            dark
                            download
                            :href="baseURL + 'file/get/' + list.letterToRange"
                            target="_blank"
                          >
                            <v-icon color="white">mdi-eye</v-icon>&nbsp;
                            <span
                              style="
                                text-transform: none;
                                font-family: mainfont;
                                font-size: 12px;
                              "
                            >
                              VIEW LETTER
                            </span></v-btn
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex
                      xs12
                      lg11
                      text-center
                      pa-2
                      v-if="list.task === 'T10'"
                    >
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>DOWNLOAD LICENSE </v-flex>
                        <v-flex xs2>
                          <v-btn
                            block
                            class="bgcustom"
                            dark
                            download
                            :href="baseURL + 'file/get/' + list.license"
                            target="_blank"
                          >
                            <v-icon color="white">mdi-eye</v-icon>&nbsp;

                            <span
                              style="
                                text-transform: none;
                                font-family: mainfont;
                                font-size: 12px;
                              "
                            >
                              LICENSE
                            </span></v-btn
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex
                      xs12
                      lg11
                      text-center
                      pa-2
                      v-if="list.task === 'T10'"
                    >
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>DOWNLOAD LETTER FROM DFO</v-flex>
                        <v-flex xs2>
                          <v-btn
                            block
                            class="bgcustom"
                            dark
                            download
                            :href="baseURL + 'file/get/' + list.letterToRange"
                            target="_blank"
                          >
                            <v-icon color="white">mdi-eye</v-icon>&nbsp;

                            <span
                              style="
                                text-transform: none;
                                font-family: mainfont;
                                font-size: 12px;
                              "
                            >
                              LETTER
                            </span></v-btn
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex
                      xs12
                      lg11
                      text-center
                      pa-2
                      v-if="list.task === 'T10'"
                    >
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left
                          >DOWNLOAD PROCEEDINGS ORDER</v-flex
                        >
                        <v-flex xs2>
                          <v-btn
                            block
                            class="bgcustom"
                            dark
                            download
                            :href="baseURL + 'file/get/' + list.proceedings"
                            target="_blank"
                          >
                            <v-icon color="white">mdi-eye</v-icon>&nbsp;

                            <span
                              style="
                                text-transform: none;
                                font-family: mainfont;
                                font-size: 12px;
                              "
                            >
                              PROCEEDINGS
                            </span></v-btn
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs12 lg11 text-center v-if="list.rangeReport" pa-2>
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>VIEW/DOWNLOAD REPORT</v-flex>
                        <v-flex xs2>
                          <v-btn
                            block
                            class="bgcustom"
                            dark
                            download
                            :href="baseURL + 'file/get/' + list.rangeReport"
                            target="_blank"
                          >
                            <v-icon color="white">mdi-download</v-icon>

                            <span
                              style="
                                text-transform: none;
                                font-family: mainfont;
                                font-size: 12px;
                              "
                            >
                              REPORT
                            </span></v-btn
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex
                      xs12
                      lg11
                      text-center
                      v-if="list.rangeMahassar"
                      pa-2
                    >
                      <v-layout wrap justify-center>
                        <v-flex xs3 text-left>VIEW/DOWNLOAD MAHASAR</v-flex>
                        <v-flex xs2>
                          <v-btn
                            block
                            class="bgcustom"
                            dark
                            download
                            :href="baseURL + 'file/get/' + list.rangeMahassar"
                            target="_blank"
                          >
                            <v-icon color="white">mdi-download</v-icon>

                            <span
                              style="
                                text-transform: none;
                                font-family: mainfont;
                                font-size: 12px;
                              "
                            >
                              MAHASAR
                            </span></v-btn
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12 pb-10>
        <v-layout wrap justify-center>
          <v-flex xs2>
            <v-btn color="primary" @click="goBack">
              <span style="text-transform: none; font-size: 15px; color: white">
                GO BACK
              </span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialogVisible2" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="mainfont"> Upload Report </span>
        </v-card-title>
        <v-card-text>
          <v-card>
            <v-file-input
              class="mainfont"
              v-model="reportFile"
              label="Choose Report file"
            ></v-file-input>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-btn class="mainfont" @click="uploadReport()" color="primary"
            >Yes</v-btn
          >
          <v-btn @click="dialogVisible2 = false" color="error">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogVisibleletter" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="mainfont"> Upload Letter </span>
        </v-card-title>
        <v-card-text>
          <v-card>
            <v-file-input
              class="mainfont"
              v-model="letterFile"
              label="Choose Letter file"
            ></v-file-input>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-btn class="mainfont" @click="uploadLetter()" color="primary"
            >Yes</v-btn
          >
          <v-btn @click="dialogVisibleletter = false" color="error">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogVisiblelicense" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="mainfont"> Upload License </span>
        </v-card-title>
        <v-card-text>
          <v-card>
            <v-file-input
              class="mainfont"
              v-model="licenseFile"
              label="Choose License file"
            ></v-file-input>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-btn class="mainfont" @click="uploadLicense()" color="primary"
            >Yes</v-btn
          >
          <v-btn @click="dialogVisiblelicense = false" color="error">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogVisibleproceedings" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="mainfont"> Upload Proceedings </span>
        </v-card-title>
        <v-card-text>
          <v-card>
            <v-file-input
              class="mainfont"
              v-model="proceedingsFile"
              label="Choose Proceedings file"
            ></v-file-input>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-btn class="mainfont" @click="uploadProceedings()" color="primary"
            >Yes</v-btn
          >
          <v-btn @click="dialogVisibleproceedings = false" color="error"
            >No</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogVisible" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="mainfont"> Upload Magasar </span>
        </v-card-title>
        <v-card-text>
          <v-card>
            <v-file-input
              class="mainfont"
              v-model="mahasarFile"
              label="Choose Mahasar file"
            ></v-file-input>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-btn class="mainfont" @click="uploadMahasar()" color="primary"
            >Yes</v-btn
          >
          <v-btn @click="dialogVisible = false" color="error">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="openDialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="mainfont"> Upload Agreement </span>
        </v-card-title>
        <v-card-text>
          <v-card> </v-card>
          <v-file-input
            class="mainfont"
            v-model="file"
            label="Choose a file"
          ></v-file-input>
        </v-card-text>
        <v-card-actions>
          <v-btn class="mainfont" @click="uploadAgreement()" color="primary"
            >Yes</v-btn
          >
          <v-btn @click="openDialog = false" color="error">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      :retain-focus="true"
      persistent
      v-model="deletedialog"
      max-width="600px"
    >
      <v-card>
        <v-card-title
          class="mainfont"
          style="color: black; font-size: 18px; font-weight: lighter"
          >Are you sure you want to Cancel?</v-card-title
        >
        <v-card-actions class="d-flex justify-center text-center">
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            text
            @click="deletedialog = false"
            class="mainfont"
            >Cancel</v-btn
          >
          <v-btn color="red" class="mainfont" text @click="deleteItem()"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <v-dialog :retain-focus="true" persistent v-model="rejectdialog" max-width="600px">
      <v-card>
        <v-card-title class="mainfont" style="color: black; font-size: 18px; font-weight: lighter">Are you sure you want
          to Reject?</v-card-title>
        <v-card-actions class="d-flex justify-center text-center">
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="rejectdialog = false" class="mainfont">Cancel</v-btn>
          <v-btn color="red" class="mainfont" text @click="rejectItem()">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <v-dialog
      :retain-focus="true"
      persistent
      v-model="rejectdialog"
      max-width="600px"
    >
      <v-card>
        <v-layout wrap justify-start>
          <v-flex xs12>
            <v-card-title
              class="mainfont"
              style="color: black; font-size: 18px; font-weight: lighter"
            >
              Are you sure you want to Reject?
            </v-card-title>
          </v-flex>
          <v-flex xs12 pl-3 pr-3>
            <v-text-field
              type="text"
              dense
              color="#283e51"
              outlined
              background-color="white"
              v-model="reason"
              hide-details
            >
              <template v-slot:label>
                <span
                  class="custom-label-text mainfont"
                  style="color: black; font-size: 14px"
                >
                  Reason<span style="color: red; font-size: 20px">*</span>
                </span>
              </template>
            </v-text-field>
          </v-flex>
        </v-layout>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            text
            @click="rejectdialog = false"
            class="mainfont"
            >Cancel</v-btn
          >
          <v-btn color="green" class="mainfont" text @click="rejectItem()"
            >Yes</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      :retain-focus="true"
      persistent
      v-model="forwarddialog"
      max-width="700px"
    >
      <v-card>
        <v-layout wrap justify-center>
          <v-flex xs8 text-left pt-5>
            <span
              class="mainfont"
              style="color: black; font-size: 18px; font-weight: lighter"
            >
              Are you sure you want to forward?
            </span>
          </v-flex>
          <v-flex xs8 pt-6 pr-4 class="mainfont">
            <v-autocomplete
              outlined
              dense
              color="black"
              :items="usernames"
              v-model="selectedUsername"
              item-text="name"
              placeholder="Select a Role"
              style="font-family: mainfont"
              item-value="name"
              item-color="#FF1313"
              hide-details
              clearable
              class="custom-autocomplete"
            >
              <template v-slot:append>
                <v-icon color="black">mdi-chevron-down</v-icon>
              </template>
            </v-autocomplete>
          </v-flex>

          <v-flex xs8 pt-5 pr-4 class="mainfont">
            <v-text-field
              placeholder="reason"
              v-model="reason"
              dense
              class="mainfont"
              outlined
              color="black"
            >
            </v-text-field>
          </v-flex>
        </v-layout>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            text
            @click="forwarddialog = false"
            class="mainfont"
            >Cancel</v-btn
          >
          <v-btn
            color="red"
            class="mainfont"
            text
            @click="forwardToUsers2()"
            :disabled="forwardInProgress"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <v-dialog
      :retain-focus="true"
      persistent
      v-model="procceddialog"
      max-width="730px"
    >
      <v-card>
        <v-layout wrap justify-center>
          <v-flex xs8 text-left pt-5>
            <span
              class="mainfont"
              style="color: black; font-size: 18px; font-weight: lighter"
            >
              Are you sure you want to proceed this application for
              <br />
              1.&nbsp;Fee Payment
              <br />
              2.&nbsp;Agreement Execution
            </span>
          </v-flex>
        </v-layout>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            text
            @click="procceddialog = false"
            class="mainfont"
            >Cancel</v-btn
          >
          <v-btn color="red" class="mainfont" text @click="proceed()">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      :retain-focus="true"
      persistent
      v-model="refunddialog"
      max-width="600px"
    >
      <v-card>
        <v-layout wrap justify-start>
          <v-flex xs12>
            <v-card-title
              class="mainfont"
              style="color: black; font-size: 18px; font-weight: lighter"
            >
              Are you sure you want to approve refund?
            </v-card-title>
          </v-flex>
        </v-layout>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            text
            @click="refunddialog = false"
            class="mainfont"
            >Cancel</v-btn
          >
          <v-btn color="green" class="mainfont" text @click="refund()"
            >Approve</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  
<script>
import axios from "axios";
// import CryptoJS from "crypto-js";
export default {
  components: {},
  data() {
    // const encryptedId = this.$route.query.id;
    // const decryptedId = CryptoJS.AES.decrypt(encryptedId, 'encryptionSecret').toString(CryptoJS.enc.Utf8);
    const today = new Date().toISOString().substr(0, 10);
    return {
      path: localStorage.getItem("path"),
      dialogVisible2: false,
      idNo: null,
      forwardInProgress: false,
      reason: null,
      procceddialog: false,
      startDate: today,
      showSnackBar: false,
      isChecked: false,
      deletedialog: false,
      refunddialog: false,
      forwarddialog: false,
      rejectdialog: false,
      openDialog: false,
      reportFile: null,
      file: null,
      mahasarFile: null,
      proceedingsFile: null,
      licenseFile: null,
      letterFile: null,
      appLoading: false,
      msg: "",
      id: this.$route.query.id,
      // id: decryptedId,
      name: null,
      dialogVisible: false,
      dialogVisibleproceedings: false,
      dialogVisiblelicense: false,
      dialogVisibleletter: false,
      barlist: [],
      range: [],
      list: {},
      divisionid: null,
      leaderdetails: [],
      idProof: "",
      Preshootfile: "",
      rangeid: null,
      filmShootingDetails: [],
      usernames: [],
      selectedUsername: null,
      selectedRanges: [],
      divisionprofiledata: {},
      divisioniddata:"",
    };
  },
  mounted() {
    this.getdivisionprofile();
    this.getList();
    this.getchallanstatus();
    
  },
  created() {
    this.uType = localStorage.getItem("uType");
  },

  computed: {
    user() {
      const uType = localStorage.getItem("uType");
      if (uType === "777") {
        return "admin";
      } else if (uType === "772") {
        return "user";
      } else if (uType === "773") {
        return "range";
      } else if (uType === "7741") {
        return "section";
      } else if (uType === "7742") {
        return "superclerk";
      } else {
        return "division";
      }
    },
  },

  methods: {
    //   navigateToRecords(id) {

    //   const encryptedId = CryptoJS.AES.encrypt(id.toString(), 'encryptionSecret').toString();
    //   this.$router.push({
    //     path: '/records',
    //     query: {
    //       id: encryptedId
    //     }
    //   });
    // },
    settoforward() {
      this.curIdToDelete = this.id;
    },
    proceed() {
      const idToDelete = this.curIdToDelete;
      if (!idToDelete) {
        return;
      }
      axios({
        url: "/division/payment/initiation/",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: this.id,
          reason: this.reason,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.deletedialog = false;
            window.location.reload();
            this.$router.push({ path: "/ApplicationDivision" });
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    refund() {
      this.appLoading = true;
      axios({
        url: "/challan/refund",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: this.id,
          refundAmount: this.list.refundAmount,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.refunddialog = false;
            // window.location.reload();
            // this.$router.push({ path: "/ApplicationDivision" });
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    forwardToUsersone(id) {
      this.getUsersone(id);
      this.appids = id;
    },
    forwardToUsers(id) {
      this.getUsers(id);
      this.appids = id;
    },
    // forwardToUsers2() {
    //   this.forward();
    // },
    forwardToUsers2() {
      if (this.forwardInProgress) return; // Don't proceed if forwarding is already in progress
      this.forwardInProgress = true; // Set flag to indicate forwarding is in progress
      this.forward();
    },
    getUsers() {
      axios({
        method: "post",
        url: "/dfo/forward/list",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: this.id,
        },
      })
        .then((response) => {
          this.usernames = [
            {
              name: `${response.data.user.username} (User)`,
              role: "User",
              userId: response.data.user._id,
            },
            {
              name: `${response.data.superintendent.username} (Superintendent)`,
              role: "Superintendent",
              userId: response.data.superintendent._id,
            },
          ];
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    getUsersone() {
      axios({
        method: "post",
        url: "/dfo/forward/list",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: this.id,
        },
      })
        .then((response) => {
          this.usernames = [
            // { name: `${response.data.user.username} (User)`, role: "User", userId: response.data.user._id },
            {
              name: `${response.data.superintendent.username} (Superintendent)`,
              role: "Superintendent",
              userId: response.data.superintendent._id,
            },
          ];
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    forward() {
      if (!this.selectedUsername) {
        // this.$refs.snackbar.showSnackbar("Please select a user to forward to.");
        this.msg = "Please select a user to forward to.";
        this.showSnackBar = true;
        this.forwardInProgress = false;
        return;
      }
      if (!this.reason) {
        // this.$refs.snackbar.showSnackbar("Please select a user to forward to.");
        this.msg = "Reason not provided.";
        this.showSnackBar = true;
        this.forwardInProgress = false;
        return;
      }
      const selectedUser = this.usernames.find(
        (user) => user.name === this.selectedUsername
      );
      if (!selectedUser) {
        return;
      }

      axios({
        method: "post",
        url: "/division/firstforward/",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: this.id,
          forwardto: selectedUser.userId,
          reason: this.reason,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            alert(response.data.msg);
            this.msg = response.data.msg;
            this.showSnackBar = true;
            window.location.reload();
            this.$router.push({ path: "/ApplicationDivision" });
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    },
    // forwardToRange() {

    //   axios({
    //     url: "/range/forward/",
    //     method: "POST",
    //     headers: {
    //       token: localStorage.getItem("token"),
    //     },
    //     data: {
    //       appid: this.id,
    //       rangeid: this.rangeid,
    //     },
    //   })
    //     .then((response) => {
    //       this.appLoading = false;
    //       if (response.data.status == true) {
    //         this.msg = response.data.msg;
    //         this.showSnackBar = true;
    //         this.deletedialog = false;
    //         this.$router.push({ path: "/ApplicationDivision" });
    //         window.location.reload();
    //       } else {
    //         this.msg = response.data.msg;
    //         this.showSnackBar = true;
    //       }
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },
    forwardToRange() {
      axios({
        url: "/range/forward/",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: this.id,
          rangeid: this.selectedRanges,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            window.alert(response.data.msg);
            // this.msg = response.data.msg;
            this.$router.push({
              path: "/ApplicationDivision",
            });
            window.location.reload();
            this.showSnackBar = true;
            this.deletedialog = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getRange() {
      this.appLoading = true;
      axios({
        method: "post",
        url: "/get/range/v2",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.divisioniddata,
        },
      })
        .then((response) => {
          this.range = response.data.data;
          this.appLoading = false;
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    getdivisionprofile() {
      this.appLoading = true;

      axios({
        method: "GET",
        url: "/profile",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.divisionprofiledata = response.data.data;
          this.divisioniddata = response.data.data._id;
          console.log("asasasa",this.divisionid);
          this.appLoading = false;
          this.getRange();
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    goBack() {
      // Use the router to navigate back to the previous page
      this.$router.go(-1);
    },
    forwardToClerk2() {
      axios({
        url: "/range/forward/clerk",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: this.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.deletedialog = false;
            // window.location.reload();
            this.$router.push({ path: "/rangebookings" });
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
    uploadAgreement() {
      const formData = new FormData();
      formData.append("userAgreement", this.file);
      formData.append("appid", this.id);
      axios
        .post(`/user/agreement`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log(response.data);
          this.openDialog = false;
          location.reload();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    uploadReport() {
      const formData = new FormData();
      formData.append("rangeReport", this.reportFile);
      formData.append("appid", this.id);
      axios
        .post(`/report/upload`, formData, {
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          (this.dialogVisible = false), location.reload();
        })
        .catch((error) => {
          // Handle error
          console.error(error);
        });
    },
    uploadMahasar() {
      const formData = new FormData();
      formData.append("rangeMahassar", this.mahasarFile);
      formData.append("appid", this.id);
      axios
        .post(`/mahassar/upload`, formData, {
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          (this.dialogVisible = false), location.reload();
        })
        .catch((error) => {
          // Handle error
          console.error(error);
        });
    },
    uploadLetter() {
      const formData = new FormData();
      formData.append("letterToRange", this.letterFile);
      formData.append("appid", this.id);
      axios
        .post(`/upload/documents`, formData, {
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          (this.dialogVisiblelicense = false), location.reload();
        })
        .catch((error) => {
          // Handle error
          console.error(error);
        });
    },
    uploadLicense() {
      const formData = new FormData();
      formData.append("license", this.licenseFile);
      formData.append("appid", this.id);
      axios
        .post(`/upload/documents`, formData, {
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          (this.dialogVisiblelicense = false), location.reload();
        })
        .catch((error) => {
          // Handle error
          console.error(error);
        });
    },
    // uploadLicensedummy() {
    //   const formData = new FormData();
    //   formData.append('licenseFile', this.licenseFile);
    //   formData.append('key', this.list.templicense);
    //   formData.append('appid', this.id);
    //   axios.post(`/proceedings/upload`, formData, {
    //     headers: {

    //       'token': localStorage.getItem('token'),
    //     },
    //     data: {
    //       'Content-Type': 'multipart/form-data',
    //       status:"L",
    //     }
    //   })
    //     .then(response => {
    //       console.log(response.data);
    //       this.dialogVisiblelicense = false,
    //         location.reload();
    //     })
    //     .catch(error => {
    //       // Handle error
    //       console.error(error);
    //     });
    // },
    uploadProceedings() {
      const formData = new FormData();
      formData.append("proceedings", this.proceedingsFile);
      formData.append("appid", this.id);
      axios
        .post(`/upload/documents`, formData, {
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            "Content-Type": "multipart/form-data",
            status: "P",
          },
        })
        .then((response) => {
          console.log(response.data);
          (this.dialogVisibleproceedings = false), location.reload();
        })
        .catch((error) => {
          // Handle error
          console.error(error);
        });
    },
    // uploadProceedingsdummy() {
    //   const formData = new FormData();
    //   formData.append('proceedingsFile', this.proceedingsFile);
    //   formData.append('appid', this.id);
    //   axios.post(`/upload/temp/documents`, formData, {
    //     headers: {

    //       'token': localStorage.getItem('token'),
    //     },
    //     data: {
    //       'Content-Type': 'multipart/form-data',
    //     }
    //   })
    //     .then(response => {
    //       console.log(response.data);
    //       this.dialogVisibleproceedings = false,
    //         location.reload();
    //     })
    //     .catch(error => {
    //       // Handle error
    //       console.error(error);
    //     });
    // },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      const selectedFile = event.target.files[0];
      console.log("Selected file:", selectedFile);
    },
    forwardToClerk() {
      axios({
        url: "/check/forward",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: this.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            window.location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    submitfee() {
      axios({
        url: "/addSecurityDepositAndShootingFee",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: this.id,
          securitydeposit: this.list.securitydeposit,
          shootingfee: this.list.shootingfee,
          refundAmount: this.list.refundAmount,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.msg = response.data.msg;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            window.location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    feePayment() {
      axios({
        url: "/fees/submission",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: this.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.forwardToClerk();
            // this.deletedialog = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteItem() {
      axios({
        url: "/cancel/application",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: this.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.deletedialog = false;
            window.location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    rejectItem() {
      axios({
        method: "POST",
        url: "/division/approval",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          status: "no",
          reason: this.reason,
          appid: this.id,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.showSnackBar = true;

            this.msg = response.data.msg;
            this.rejectdialog = false;
            this.$router.push({ path: "/ApplicationDivision" });
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.rejectdialog = false;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    },
    getAcknowledgment() {
      const token = localStorage.getItem("token");
      const downloadUrl = "/acknowledgement/pdf";
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          appid: this.id,
        },
        responseType: "blob",
      };

      axios
        .get(downloadUrl, config)
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);

          // Open the URL in a new tab
          const newTab = window.open();
          newTab.document.write(
            '<iframe width="100%" height="100%" src="' +
              url +
              '" type="application/pdf"></iframe>'
          );
          newTab.document.close();
        })
        .catch((error) => {
          console.error("Error downloading acknowledgement:", error);
        });
    },
    getProceedings() {
      const token = localStorage.getItem("token");
      const downloadUrl = "/new/proceedings/pdf";
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          appid: this.id,
        },
        responseType: "blob",
      };

      axios
        .get(downloadUrl, config)
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);

          // Create a temporary anchor element
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.download = "proceedings.pdf"; // Specify the filename for download
          anchor.click();

          // Clean up
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Error downloading proceedings:", error);
        });
    },
    // getLicense() {
    //   const token = localStorage.getItem("token");
    //   const downloadUrl = "/new/licence/pdf";
    //   const config = {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //     params: {
    //       appid: this.id,
    //     },
    //     responseType: "blob",
    //   };

    //   axios
    //     .get(downloadUrl, config)
    //     .then((response) => {
    //       const blob = new Blob([response.data], { type: 'application/pdf' });
    //       const url = window.URL.createObjectURL(blob);

    //       // Open the URL in a new tab
    //       const newTab = window.open();
    //       newTab.document.write('<iframe width="100%" height="100%" src="' + url + '" type="application/pdf"></iframe>');
    //       newTab.document.close();
    //     })
    //     .catch((error) => {
    //       console.error("Error downloading license:", error);
    //     });
    // },
    // getLetter() {
    //   const token = localStorage.getItem("token");
    //   const downloadUrl = "/letterto/range/pdf";
    //   const config = {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //     params: {
    //       appid: this.id,
    //     },
    //     responseType: "blob",
    //   };

    //   axios
    //     .get(downloadUrl, config)
    //     .then((response) => {
    //       const blob = new Blob([response.data], { type: 'application/pdf' });
    //       const url = window.URL.createObjectURL(blob);

    //       // Open the URL in a new tab
    //       const newTab = window.open();
    //       newTab.document.write('<iframe width="100%" height="100%" src="' + url + '" type="application/pdf"></iframe>');
    //       newTab.document.close();
    //     })
    //     .catch((error) => {
    //       console.error("Error downloading letter:", error);
    //     });
    // },
    getLicense() {
      const token = localStorage.getItem("token");
      const downloadUrl = "/new/licence/pdf";
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          appid: this.id,
        },
        responseType: "blob",
      };

      axios
        .get(downloadUrl, config)
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);

          // Create a temporary anchor element
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.download = "license.pdf"; // Specify the filename for download
          anchor.click();

          // Clean up
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Error downloading license:", error);
        });
    },
    getLetter() {
      const token = localStorage.getItem("token");
      const downloadUrl = "/letterto/range/pdf";
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          appid: this.id,
        },
        responseType: "blob",
      };

      axios
        .get(downloadUrl, config)
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);

          // Create a temporary anchor element
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.download = "letter.pdf"; // Specify the filename for download
          anchor.click();

          // Clean up
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Error downloading letter:", error);
        });
    },
    getApplication() {
      const token = localStorage.getItem("token");
      const downloadUrl = "/application/pdf";
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          appid: this.id,
        },
        responseType: "blob",
      };

      axios
        .get(downloadUrl, config)
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);

          // Open the URL in a new tab
          const newTab = window.open();
          newTab.document.write(
            '<iframe width="100%" height="100%" src="' +
              url +
              '" type="application/pdf"></iframe>'
          );
          newTab.document.close();
        })
        .catch((error) => {
          console.error("Error downloading acknowledgement:", error);
        });
    },
    getAgreement() {
      const token = localStorage.getItem("token");
      const downloadUrl = "/agreement/pdf";
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          appid: this.id,
        },
        responseType: "blob",
      };

      axios
        .get(downloadUrl, config)
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);

          // Open the URL in a new tab
          const newTab = window.open();
          newTab.document.write(
            '<iframe width="100%" height="100%" src="' +
              url +
              '" type="application/pdf"></iframe>'
          );
          newTab.document.close();
        })
        .catch((error) => {
          console.error("Error downloading acknowledgement:", error);
        });
    },
    // getAgreement() {
    //   const token = localStorage.getItem("token");
    //   const downloadUrl = "/agreement/pdf";
    //   const config = {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //     params: {
    //       appid: this.id,
    //     },
    //     responseType: "blob",
    //   };

    //   axios
    //     .get(downloadUrl, config)
    //     .then((response) => {
    //       const blob = new Blob([response.data], { type: 'application/pdf' });
    //       const url = window.URL.createObjectURL(blob);

    //       // Set the window location to the PDF URL
    //       window.location.href = url;
    //     })
    //     .catch((error) => {
    //       console.error("Error downloading acknowledgement:", error);
    //     });
    // },
    getList() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/single/application",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: this.id,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.list = response.data.data;
            this.filmShootingDetails = this.list.filmShootingDetails;
            this.memberDetails = this.list.memberDetails;
            this.idProof = this.list.idProof;
            this.Preshootfile = this.list.Preshootfile;
            this.reqLetter = this.list.reqLetter;
            this.participantfile = this.list.participantfile;
            this.leaderIdProof = this.list.leaderIdProof;
            this.rangeMahassars = this.response.data.data.rangeMahassars;
            this.rangeReports = this.response.data.data.rangeReports;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    },
    getchallanstatus() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/challan/refund/checkstatus",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: this.id,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    },
  },
};
</script>
  
  
<style>
.subheading {
  font-family: mainfont;
  color: #42275a;
  text-transform: none;
  font-size: 17px;
  font-weight: bold;
}

.heading {
  font-family: mainfont;
  color: white;
  text-transform: none;
  font-size: 22px;
}
.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>